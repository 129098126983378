import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Terms of Service (Oct 23, 2020)',
  header: 'Terms of Service (Oct 23, 2020)',
  description: 'Uploadcare Terms of Service (Oct 23, 2020): please read this Agreement carefully before using our website and application.',
  canonical: 'https://uploadcare.com/about/terms/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Note: The previous version of our Terms of Service can be found `}<a parentName="p" {...{
        "href": "/about/terms_20180525/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Effective Date: Oct 23, 2020`}</p>
    <p>{`Uploadcare Inc. (`}<em parentName="p">{`“Uploadcare”`}</em>{`, `}<em parentName="p">{`“our”`}</em>{`, `}<em parentName="p">{`“us”`}</em>{` or `}<em parentName="p">{`“we”`}</em>{`) provides the Uploadcare website and application.`}</p>
    <p>{`The following terms and conditions govern your use of the `}<a parentName="p" {...{
        "href": "https://uploadcare.com/"
      }}>{`https://uploadcare.com/`}</a>{` website (the `}<em parentName="p">{`“Website”`}</em>{`), all content, services and products available at or through the Website, including, but not limited to the Uploadcare hosting services, APIs, libraries, widgets, plugins, modules, and any Upgrades (collectively with the Website referred to as the `}<em parentName="p">{`“Services”`}</em>{`). The Services are owned and operated by Uploadcare.`}</p>
    <p>{`The Website and Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Uploadcare's Privacy Policy) and procedures that may be published from time to time on this Site by Uploadcare (collectively, the `}<em parentName="p">{`“Agreement”`}</em>{`).`}</p>
    <p>{`Please read this Agreement carefully before accessing or using the Services. By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access the Website or use any Services. If these terms and conditions are considered an offer by Uploadcare, acceptance is expressly limited to these terms. The Services is available only to individuals who are at least 13 years old (16 years for individuals from EU).`}</p>
    <h2 {...{
      "id": "1-your-uploadcare-account-and-site"
    }}><a parentName="h2" {...{
        "href": "#1-your-uploadcare-account-and-site"
      }}>{`1. Your Uploadcare Account and Site`}</a></h2>
    <p>{`If you create an account on the Services, you are responsible for maintaining the security of your account, account credentials and account content, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the Services. You must not create more than one account per every website served by the Services. You must not describe or assign content to your account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and Uploadcare may change or remove any content or files that it considers inappropriate or unlawful, or otherwise likely to cause Uploadcare liability. You must immediately notify Uploadcare of any unauthorized uses of your account or any other breaches of security. Uploadcare will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.`}</p>
    <h2 {...{
      "id": "2-responsibility-of-users-and-contributors"
    }}><a parentName="h2" {...{
        "href": "#2-responsibility-of-users-and-contributors"
      }}>{`2. Responsibility of Users and Contributors`}</a></h2>
    <p>{`If you operate an account, post content or files to the Services, or otherwise make (or allow any third party to make) any material or information available by means of the Services (any such material, `}<em parentName="p">{`“Content”`}</em>{`), you are entirely responsible for the content of, and any harm resulting from, that Content. By making Content available, you represent and warrant that:`}</p>
    <ul>
      <li parentName="ul">{`You (or any third party on your behalf) shall be using any functionality of the Services for their intended purposes only (e.g. not use the content delivery network Services functionality as CDN origin);`}</li>
      <li parentName="ul">{`You shall not use the Services, Upgrades or Paid Services, through a vulnerability or otherwise, in a way that prevents the Services from tracking your usage, generating invoices or charging you through your credit card or other payment mechanism;`}</li>
      <li parentName="ul">{`You shall be responsible for using commercially reasonable efforts to prevent unauthorized access to or use of the Services;`}</li>
      <li parentName="ul">{`You shall promptly notify Uploadcare of any unauthorized access or use of the Services;`}</li>
      <li parentName="ul">{`You shall not reverse engineer, decompile, translate, disassemble or otherwise attempt to extract any or all of the proprietary source code of the Services;`}</li>
      <li parentName="ul">{`You shall not alter, remove or obscure any copyright, trademark or other proprietary notices or confidentiality legend on the Services;`}</li>
      <li parentName="ul">{`The downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of Uploadcare or any third party;`}</li>
      <li parentName="ul">{`If any third party has rigths to intellectual property you create, you have either (i) received permission from such third party to post or make available the Content, including but not limited to any software, or (ii) secured from the third party a waiver as to all rights in or to the Content;`}</li>
      <li parentName="ul">{`You have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;`}</li>
      <li parentName="ul">{`The Content does not contain or install any viruses, worms, malware, trojan horses or other harmful or destructive content or other item that may remove or change any content of the Services or attempt to circumvent security or interfere with the proper working of the Services or the servers on which it is hosted;`}</li>
      <li parentName="ul">{`The Content is not spam and does not contain unethical content or content designed to be used as a part of any unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);`}</li>
      <li parentName="ul">{`The Content is not pornographic, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party;`}</li>
      <li parentName="ul">{`You have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses and effects of the materials, whether requested to do so by Uploadcare or otherwise; and`}</li>
      <li parentName="ul">{`Your Content and usage of the Services does not violate applicable laws and governmental regulations.`}</li>
    </ul>
    <h2 {...{
      "id": "3-license-grant"
    }}><a parentName="h2" {...{
        "href": "#3-license-grant"
      }}>{`3. License Grant`}</a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`License to Services`}</strong>{`. Subject to your compliance with these Agreement, Uploadcare hereby grants a personal, non-exclusive, non-commercial, non-sublicensable, revocable and non-transferable license to use the Website and Services in accordance with the terms and conditions of this Agreement. No rights are granted to you except as expressly set forth in the Agreement.`}</li>
      <li parentName="ul"><strong parentName="li">{`License to Content`}</strong>{`. By submitting Content to Uploadcare for inclusion on the Services, you grant Uploadcare a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing and promoting your account and providing the Services. Uploadcare has the right to grant to its contractors sublicenses under the licenses granted under this section and for the same purpose. If you request for the deletion of your Content, Uploadcare will use reasonable efforts to remove it from the Services, but you acknowledge that caching or references to the Content may not be made immediately unavailable.`}</li>
      <li parentName="ul"><strong parentName="li">{`Feedback`}</strong>{`. You grant to Uploadcare worldwide, perpetual, irrevocable, royalty-free license to use and incorporate into the Services and Website any suggestion, enhancement request, recommendation, correction, or other feedback provided by you.`}</li>
      <li parentName="ul"><strong parentName="li">{`Trademarks`}</strong>{`. You consent to Uploadcare’s use of your name and logo and general description of your relationship with Uploadcare in press releases and other marketing materials and appearances. You grant to Uploadcare worldwide, a non-exclusive, royalty-free, fully-paid up license to use and reproduce your trademarks, tradenames and logos in Uploadcare’ marketing materials and website and to indicate that you are an Uploadcare customer. Uploadcare will abide by any written trademark usage guidelines provided by you. All goodwill arising out of the use of your trademarks, tradenames and logos shall inure to your benefit. To decline Uploadcare this license you need to email `}<a parentName="li" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a>{` stating that you do not wish to be used as a reference.`}</li>
    </ul>
    <h2 {...{
      "id": "4-violative-content"
    }}><a parentName="h2" {...{
        "href": "#4-violative-content"
      }}>{`4. Violative Content`}</a></h2>
    <p>{`Without limiting any of those representations or warranties, Uploadcare has the right (though not the obligation) to, in Uploadcare’s sole discretion (i) refuse or remove any content that, in Uploadcare’s reasonable opinion, violates any Uploadcare policy or is in any way harmful or objectionable, or (ii) terminate or deny access to and use of the Services to any individual or entity for any reason. Uploadcare will have no obligation to provide a refund of any amounts previously paid.`}</p>
    <h2 {...{
      "id": "5-paid-services"
    }}><a parentName="h2" {...{
        "href": "#5-paid-services"
      }}>{`5. Paid Services`}</a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fees; Payment`}</strong>{`. You can sign up for paid Services provided by us (`}<em parentName="li">{`“Paid Services”`}</em>{`). By signing up for a Paid Services account you agree to pay Uploadcare the monthly fees. There are two types of monthly fees: `}<em parentName="li">{`“Subscription Fees”`}</em>{` and `}<em parentName="li">{`“Usage Fees”`}</em>{` indicated at `}<a parentName="li" {...{
          "href": "https://uploadcare.com/pricing/"
        }}>{`https://uploadcare.com/pricing/`}</a>{` or as indicated in your Order Form. By signing up for a Paid Services account you agree to pay Uploadcare both monthly Subscription Fees and Usage Fees.`}</li>
      <li parentName="ul"><strong parentName="li">{`Subscription Fees`}</strong>{` are based on the selected subscription type and cover the certain number of units. Subscription Fees are charged in advance on the day you sign up for the Paid Services and will cover the use of Paid Services for a monthly or annual period as indicated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Usage Fees`}</strong>{` are charged when you exceed the number of units included in your subscription plan. Payments related to Usage Fees will be charged at the end of each month in which there has been an excess and are based on the tariffs applied to your subscription type.`}</li>
      <li parentName="ul"><strong parentName="li">{`Refund, Change and Cancellation of Services`}</strong>{`. Paid Services fees are not refundable. Uploadcare reserves the right to change the payment terms and fees upon thirty days prior written notice to you. Paid Services may be canceled by you at any time through your account settings page, subject to the terms of this Agreement.`}</li>
      <li parentName="ul"><strong parentName="li">{`Discount`}</strong>{`. Uploadcare may offer you, at its sole discretion, Paid Services at a discounted rate (the `}<em parentName="li">{`“Discounted Services”`}</em>{`) if you commit to subscribe to the Services for an annual or longer subscription period (the `}<em parentName="li">{`“Discount Subscription Period”`}</em>{`) or as indicated in your Order Form. Payments will be charged in advance on the day you sign up for a Discounted Service and will cover the use of that service for a monthly or annual subscription period as indicated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Invoice`}</strong>{`. Applicable fees for Paid Services or Discounted Services, as the case may be, will be invoiced on the day you sign up for a Service and shall be due in advance of using such Services as provided in the Order Form. If any invoiced amount based on any combination of Subscription Fees and Usage Fees is not received by Uploadcare by the due date, then without limiting Uploadcare’s rights or remedies: (a) Uploadcare may suspend your access to the Website and/or Services until the invoiced amount will be received through your credit card or other payment mechanism; (b) those charges may accrue late interest at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower; (c) Uploadcare may condition future subscription renewals on shorter payment terms; and (d) Uploadcare may accelerate your unpaid fee obligations to become immediately due and payable, and suspend the provision of Services to you until the overdue amounts are paid in full.`}</li>
      <li parentName="ul"><strong parentName="li">{`Automatic Renewal`}</strong>{`. Unless you notify Uploadcare before the end of the applicable subscription period that you want to cancel a Paid Service or Discounted Service, your subscription will automatically renew and you authorize Uploadcare, or a third party through which you transact (without notice to you, unless required by applicable law) to collect the then-applicable annual or monthly subscription fee for such Service (as well as any taxes) using any credit card or other payment mechanism we have on record for you. If all credit cards we have on file for you or any other payment method used by you are declined for payment of your membership or subscription fees, Uploadcare may suspend or cancel the provision of Services to you, as applicable, unless you provide us with a new credit card or another payment mechanism. If you provide us with a new credit card and are successfully charged before your subscription is cancelled, your new subscription period will be based on the original renewal date and not the date of the successful charge.`}</li>
      <li parentName="ul"><strong parentName="li">{`Support`}</strong>{`. Certain Paid Services packages include access to priority email and telephone support. “Email support” means the ability to make requests for technical support assistance by email at any time (with reasonable efforts by Uploadcare to respond within one business day) concerning the use of the Paid Services. “Priority” means that support for Paid Services customers takes priority over support for users of the standard, free Uploadcare Services. All Paid Services support will be provided in accordance with Uploadcare standard Paid Services practices, procedures and policies.`}</li>
      <li parentName="ul"><strong parentName="li">{`Order Form`}</strong>{`. In addition to the terms and conditions of this Agreement, the provision of Paid Services and Discounted Services and your obligations relating to the same will be subjected to the terms and conditions of any applicable order form provided by Uploadcare to you (the `}<em parentName="li">{`“Order Form”`}</em>{`). In terms of any conflict between the provisions of this Agreement and the Order Form, the provisions of this Agreement shall prevail except where the Order Form expressly provides that it shall prevail over a provision of the Agreement.`}</li>
      <li parentName="ul"><strong parentName="li">{`Taxes`}</strong>{`. All fees for Services and any Upgrades (as defined below) are exclusive of taxes, levies, duties or charges imposed by government authorities (collectively, `}<em parentName="li">{`“Taxes”`}</em>{`). You shall be solely responsible for all sales, service, value-added, use, excise, consumption and any other Taxes on amounts payable by Customer under the Order Forms and this Agreement (other than any Taxes on Uploadcare’s income, revenues, gross receipts, personnel or assets). Without limiting the foregoing, if you are required to deduct or withhold any Taxes under applicable laws outside the United States, you shall remit such Taxes in accordance with those applicable laws and all fees payable shall be increased so that Uploadcare receives an amount equal to the sum it would have received had no withholding or deduction been made. If an applicable tax authority requires Uploadcare to pay any taxes that should have been payable by you, Uploadcare will advise you in writing, and you will promptly reimburse Uploadcare for the amounts paid.`}</li>
    </ul>
    <h2 {...{
      "id": "6-upgrades"
    }}><a parentName="h2" {...{
        "href": "#6-upgrades"
      }}>{`6. Upgrades`}</a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`General Terms`}</strong>{`. Optional Paid Services (including `}<em parentName="li">{`“Service Upgrades”`}</em>{` and `}<em parentName="li">{`“Usage Upgrades”`}</em>{`, collectively, `}<em parentName="li">{`“Upgrades”`}</em>{`) are available through the use of the Services. By selecting an Upgrade you agree to pay Uploadcare the monthly or annual subscription fees indicated for that service. Payments will be charged on a pre-pay basis on the day you sign up for an Upgrade and will cover the use of that service for a monthly or annual subscription period as indicated. Upgrade fees are not refundable.`}</li>
      <li parentName="ul"><strong parentName="li">{`Automatic Renewal`}</strong>{`. Unless you notify Uploadcare before the end of the applicable subscription period that you want to cancel an Upgrade, your Upgrade subscription will automatically renew and you authorize us to collect the then-applicable annual or monthly subscription fee for such Upgrade (as well as any taxes) using any credit card or other payment mechanism we have on record for you. Upgrades can be canceled at any time by notifying Uploadcare through the means of the Services dashboard.`}</li>
    </ul>
    <h2 {...{
      "id": "7-responsibility-of-services-users-and-visitors"
    }}><a parentName="h2" {...{
        "href": "#7-responsibility-of-services-users-and-visitors"
      }}>{`7. Responsibility of Services Users and Visitors`}</a></h2>
    <p>{`Uploadcare has not reviewed, and cannot review, all of the material, including computer software, posted to the Services, and cannot therefore be responsible for that material’s content, use or effects. By operating the Services, Uploadcare does not represent or imply that it endorses the material there posted, or that it believes such material to be accurate, useful or non- harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, trojan horses, and other harmful or destructive content. The Services may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The Services may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. Uploadcare disclaims any responsibility and you acknowledge and agree that Uploadcare shall not be responsible or liable, directly or indirectly, for any harm, damage or loss caused or alleged to be resulting from the use by users or visitors of the Services, or from any downloading by those visitors of content there posted.`}</p>
    <h2 {...{
      "id": "8-content-posted-on-other-resources"
    }}><a parentName="h2" {...{
        "href": "#8-content-posted-on-other-resources"
      }}>{`8. Content Posted on Other Resources`}</a></h2>
    <p>{`We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites, webpages, computer software, applications, libraries, APIs or services (collectively, `}<em parentName="p">{`“Resources”`}</em>{`) to which we link, and that link to us. Uploadcare does not have any control over those non-Uploadcare Resources, and is not responsible for their contents or their use. By linking to a non-Uploadcare Resource, Uploadcare does not represent or imply that it endorses such Resource. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, trojan horses, and other harmful or destructive content present on non-Uploadcare Resource. Uploadcare disclaims any responsibility and you acknowledge and agree that Uploadcare shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such non-Uploadcare Resource.`}</p>
    <h2 {...{
      "id": "9-general-representation-and-warranty"
    }}><a parentName="h2" {...{
        "href": "#9-general-representation-and-warranty"
      }}>{`9. General Representation and Warranty`}</a></h2>
    <p>{`You represent and warrant that (i) your use of the Services will be in strict accordance with the Uploadcare's Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside) and (ii) your use of the Services will not infringe or misappropriate the intellectual property rights of any third party.`}</p>
    <h2 {...{
      "id": "10-copyright-infringement-and-dmca-policy"
    }}><a parentName="h2" {...{
        "href": "#10-copyright-infringement-and-dmca-policy"
      }}>{`10. Copyright Infringement and DMCA Policy`}</a></h2>
    <p>{`As Uploadcare asks others to respect its intellectual property rights, it respects the intellectual property rights of others. Please notify us if you believe that material located on or linked to by Uploadcare violates your copyright or if you are aware of any intellectual property right infringement by a user of our Services. In compliance with Section 512 of the Digital Millennium Copyright Act of 1998 (the “DMCA”), we designate the following individual to receive any notifications of an alleged infringement:`}</p>
    <p><strong parentName="p">{`Copyright Agent`}</strong></p>
    <p>{`Uploadcare, Inc
Attn: Uploadcare Designated Agent
18401 Collins Ave, Ste. 100-241,
Sunny Isles Beach, FL, 33160
United States of America.
`}<a parentName="p" {...{
        "href": "mailto:dmca@uploadcare.com"
      }}>{`dmca@uploadcare.com`}</a></p>
    <p>{`An effective notification should include:`}</p>
    <ul>
      <li parentName="ul">{`A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;`}</li>
      <li parentName="ul">{`Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;`}</li>
      <li parentName="ul">{`Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;`}</li>
      <li parentName="ul">{`Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;`}</li>
      <li parentName="ul">{`A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;`}</li>
      <li parentName="ul">{`A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.`}</li>
    </ul>
    <p>{`A notification that does not comply with all the above-mentioned requirements may not be valid. If you believe that a notice was wrongly filed against you, under the DMCA, you may file a counter-notice to our designated individual with the following information:`}</p>
    <ul>
      <li parentName="ul">{`Your physical or electronic signature;`}</li>
      <li parentName="ul">{`Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;`}</li>
      <li parentName="ul">{`A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled;`}</li>
      <li parentName="ul">{`Your name, address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which we are located, or if your address is outside of the United States, for any judicial district in which we may be found, and that you will accept service of process from the person who provided notification or an agent of such person.`}</li>
    </ul>
    <p>{`All limitations of access, suspensions, and terminations for cause shall be made in Uploadcare’s sole discretion and Uploadcare shall not be liable to you or any third party for any termination of your or any third party’s account or access to the Services.`}</p>
    <p>{`It is often difficult to determine if your intellectual property rights have been violated or if the DMCA requirements have been met. You acknowledge that if you fail to comply with all of the requirements of this Section, your DMCA notice may not be valid. We may request additional information before we remove any infringing material. If a dispute develops as to the correct owner of the rights in question, we reserve the right to remove your content along with that of the alleged infringer pending resolution of the matter. Uploadcare will provide you with notice if your materials have been removed based on a third-party complaint of alleged infringement of the third-party’s intellectual property rights. We have a policy of terminating accounts of users who repeatedly and intentionally infringe the intellectual property rights of others.`}</p>
    <p>{`IF YOU KNOWINGLY MISREPRESENT THAT MATERIAL IS OR IS NOT INFRINGING, YOU MAY BE SUBJECT TO CIVIL PENALTIES. THESE INCLUDE MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS' FEES INCURRED BY UPLOADCARE, BY ANY COPYRIGHT OWNER, OR BY ANY COPYRIGHT OWNER'S LICENSEE THAT IS INJURED AS A RESULT OF RELIANCE UPON YOUR MISREPRESENTATION. YOU MAY ALSO BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY. You agree we may provide your notice to the provider of the allegedly infringing material. The obligations under this section shall survive any termination or expiration of this Agreement or your use of the Services.`}</p>
    <h2 {...{
      "id": "11-intellectual-property-ownership"
    }}><a parentName="h2" {...{
        "href": "#11-intellectual-property-ownership"
      }}>{`11. Intellectual Property Ownership`}</a></h2>
    <p>{`This Agreement does not transfer from Uploadcare to you any Uploadcare or third party intellectual property, and all right, title and interest in and to such property, including but not limited to the Services, will remain (as between the parties) solely with Uploadcare. Uploadcare, Uploadcare Inc., Uploadcare.com, the Uploadcare logo, and all other trademarks, service marks, graphics and logos used in connection with Uploadcare, or the Services are trademarks or registered trademarks of Uploadcare or Uploadcare’s licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any Uploadcare or third-party trademarks. No rights are granted to you except as expressly set forth in the Agreement.`}</p>
    <h2 {...{
      "id": "12-advertisements"
    }}><a parentName="h2" {...{
        "href": "#12-advertisements"
      }}>{`12. Advertisements`}</a></h2>
    <p>{`Uploadcare reserves the right to display advertisements in your Uploadcare display widgets unless you have purchased an ad-free Paid Services account.`}</p>
    <h2 {...{
      "id": "13-mailing-list"
    }}><a parentName="h2" {...{
        "href": "#13-mailing-list"
      }}>{`13. Mailing List`}</a></h2>
    <p>{`You can unsubscribe from our mailing list at any time directly from the unsubscribe link included in each electronic marketing message we send to you. If you do so, we will promptly update our databases, and will take all reasonable steps to meet your request at the earliest possible opportunity, but we may continue to contact you to the extent necessary for the purposes of providing our Services.`}</p>
    <p>{`When providing Uploadcare with your personal information, the processing of your personal information is governed by the Uploadcare's Privacy Policy. Uploadcare reserves the right to contact you personally for the means of improving the Services.`}</p>
    <h2 {...{
      "id": "14-changes"
    }}><a parentName="h2" {...{
        "href": "#14-changes"
      }}>{`14. Changes`}</a></h2>
    <p>{`Uploadcare reserves the right, at its sole discretion, to modify or replace any part of this Agreement. Except as expressly set, such changes will be effective after three days upon posting the new version of Agreement on `}<a parentName="p" {...{
        "href": "https://uploadcare.com/about/terms/"
      }}>{`https://uploadcare.com/about/terms/`}</a>{`. It is your responsibility to check this `}<a parentName="p" {...{
        "href": "https://uploadcare.com/about/terms/"
      }}>{`https://uploadcare.com/about/terms/`}</a>{` periodically for changes. Your continued use of or access to the Website or Services following the posting of any changes to this Agreement constitutes acceptance of those changes. Uploadcare may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.`}</p>
    <h2 {...{
      "id": "15-termination"
    }}><a parentName="h2" {...{
        "href": "#15-termination"
      }}>{`15. Termination`}</a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Termination Rights for Services — Excluding Paid or Discounted Services or Upgrades`}</strong>{`. Uploadcare may terminate your access to all or any part of the Services, at any time, at its own discretion, with or without notice, effective immediately. Subject to the terms of this Agreement, if you wish to terminate this Agreement or your Uploadcare account (if you have one), you may simply discontinue using the Services.`}</li>
      <li parentName="ul"><strong parentName="li">{`Termination for Cause — Paid or Discounted Services or Upgrades`}</strong>{`. In addition to Uploadcare’s right to terminate this Agreement under the Agreement, you or Uploadcare may terminate the Agreement for cause (a) upon 30 days’ written notice to the other of a material breach if the breach remains uncured at the expiration of the notice period or (b) if the other party (i) becomes the subject of a proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors to the extent permitted by applicable laws or governmental regulations, (ii) goes out of business or (iii) ceases its operations; provided that, Uploadcare may terminate the Services immediately as part of a general shut down of our service.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cancellation of Discounted Services`}</strong>{`. Except in the event of termination for cause as in section 15(b) above, Discounted Services are (a) non-cancellable; and (b) cannot be decreased for the first six (6) months of the Discount Subscription Period. In the event that you choose to cancel or decrease the Discounted service after six months but before the end of the Discount Subscription Period, you shall pay Uploadcare cancellation charges equal to the discount granted you by Uploadcare for committing to a Discount Subscription Period. You authorize us to collect the applicable cancellation charges (as well as any taxes) using any credit card or other payment mechanism we have on record for you. Cancellation of Discounted Services may be effected through your account settings page and is subject to the terms of this Agreement. All fees paid for commitments to a Discount Subscription Period are not refundable.`}</li>
      <li parentName="ul"><strong parentName="li">{`Effect of Termination`}</strong>{`. Upon termination of this Agreement, your rights under these Agreement will automatically terminate. Accordingly, upon termination, your right to use the Services will immediately cease and you will lose all data related to your account. All fees that were due prior to the termination of the Agreement and Services shall remain due and become immediately payable. In such an event, you must cease all use of the Services. Our failure to insist upon or enforce your strict compliance with these Agreement will not constitute a waiver of any of our rights. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, confidentiality, payment obligations and limitations of liability.`}</li>
    </ul>
    <h2 {...{
      "id": "16-confidentiality"
    }}><a parentName="h2" {...{
        "href": "#16-confidentiality"
      }}>{`16. Confidentiality`}</a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Confidential Information`}</strong>{`. “Confidential Information” means all information disclosed by a party (`}<em parentName="li">{`“Disclosing Party”`}</em>{`) to the other party (`}<em parentName="li">{`“Receiving Party”`}</em>{`), whether orally or in writing, that is designated as confidential or, given the nature of the information and the circumstances of disclosure, should be understood to be confidential. Your Confidential Information includes the Content; Uploadcare’s Confidential Information includes the Services and any documentation related thereto; and Confidential Information of each party includes but is not limited to the terms and conditions of this Agreement and all Order Forms, including pricing, business and marketing plans, technology and technical information, product plans and designs, and business processes disclosed by each party in connection with this Agreement. Confidential Information does not include information that: (a) is at the time of disclosure, or later becomes, generally known to the public through no fault of Receiving Party; (b) was known to the Receiving Party with no obligation of confidentiality prior to disclosure by Disclosing Party, as proven by records of Receiving Party; (c) is disclosed to Receiving Party by a third party who did not directly or indirectly obtain the information subject to any confidentiality obligation; or (d) is at any time independently developed by Receiving Party without use of Disclosing Party’s Confidential Information as proven by records of Receiving Party.`}</li>
      <li parentName="ul"><strong parentName="li">{`Confidentiality Obligation`}</strong>{`. Except as provided in Section 16(c) below, the Receiving Party shall not disclose or otherwise make available any Confidential Information of Disclosing Party to anyone except those of its employees, directors, attorneys, agents and consultants who: (a) need to know the Confidential Information in connection with the purpose of the Agreement and (b) who have previously agreed to be bound by confidentiality obligations no less stringent than those in the Agreement. Each party shall (x) safeguard all Confidential Information of the other party with at least the same degree of care (but no less than reasonable care) as it uses to safeguard its own confidential information and (y) not use any Confidential Information of the other party for any purpose outside the scope of the Agreement. In the event of any breach or threatened breach by the Receiving Party of its obligations under this Section 16, the Disclosing Party will be entitled to seek injunctive and other equitable relief to enforce such obligations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Disclosure`}</strong>{`. If Receiving Party is compelled by law to disclose Confidential Information of Disclosing Party, then to the extent legally permitted, Receiving Party shall provide Disclosing Party with prior notice of the compelled disclosure and reasonable assistance, at Disclosing Party’s cost, if Disclosing Party wishes to contest the compelled disclosure. Any compelled disclosure shall be limited to the extent required, and shall be subject to confidentiality protections to the extent practicable. If Receiving Party is compelled by law to disclose Disclosing Party’s Confidential Information as part of a civil proceeding to which Disclosing Party is a party, and Disclosing Party is not contesting the disclosure, Disclosing Party will reimburse Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information.`}</li>
    </ul>
    <h2 {...{
      "id": "17-disclaimer-of-warranties"
    }}><a parentName="h2" {...{
        "href": "#17-disclaimer-of-warranties"
      }}>{`17. Disclaimer of Warranties`}</a></h2>
    <p>{`THE SERVICES, WEBSITE AND ANY AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR BAILMENT OF YOUR DATA ON OUR SERVERS OR SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO US OR VIA THE SERVICES. YOU ALSO ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, THAT DEFECTS WILL BE CORRECTED, NOR DO WE MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES, AND NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM US OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.`}</p>
    <p>{`YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICES AND WEBSITE IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT YOUR USE OF THE SERVICES AND WEBSITE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND WE SPECIFICALLY DISCLAIM SUCH WARRANTIES. BY ACCESSING OR USING THE SERVICES OR WEBSITE YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SERVICES.`}</p>
    <p>{`TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL UPLOADCARE, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.`}</p>
    <p>{`SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU. NOTHING IN THIS AGREEMENT SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. THIS SECTION SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF YOUR RELATIONSHIP WITH UPLOADCARE.`}</p>
    <p>{`Note: Uploadcare may sign a separate `}<em parentName="p">{`“Service Level Agreement”`}</em>{` (SLA) when the SLA implementation is desired or needed by a Paid Services User. Please contact us at `}<a parentName="p" {...{
        "href": "mailto:help@uploadcare.com"
      }}>{`help@uploadcare.com`}</a>{` for details.`}</p>
    <h2 {...{
      "id": "18-limitation-of-liability"
    }}><a parentName="h2" {...{
        "href": "#18-limitation-of-liability"
      }}>{`18. Limitation of Liability`}</a></h2>
    <p>{`In no event will Uploadcare, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Uploadcare under this Agreement during the twelve (12) month period prior to the cause of action on the Order Form from which the cause of action arose. Uploadcare shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.`}</p>
    <p>{`IN NO EVENT SHALL UPLOADCARE BE LIABLE FOR ANY INDIRECT, ECONOMIC, SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES RELATED TO: (A) THE SERVICES; (B) THE UPLOADCARE WEBSITE; (C) YOUR CONTENT; (D) YOUR USE OF, INABILITY TO USE, OR THE PERFORMANCE OF THE WEBSITE OR SERVICES; (E) ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY UPLOADCARE OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR USE OF THE WEBSITE OR SERVICES; (F) ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (G) ANY ERRORS OR OMISSIONS IN THE SERVICES' or WEBSITE’S TECHNICAL OPERATION; OR (H) ANY DAMAGE THAT RESULTS FROM EVENTS BEYOND OUR REASONABLE CONTROL, SUCH AS DAMAGES TO ANY YOUR COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF FORESEEABLE OR EVEN IF UPLOADCARE HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL UPLOADCARE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR ACTION EXCEED THE AMOUNTS PAID BY YOU, IF ANY, TO UPLOADCARE IN THE PAST TWELVE MONTHS. THE PRIOR LIMITATION ON DAMAGES IS NOT INTENDED TO LIMIT UPLOADCARE’S OBLIGATION TO PAY PREVAILING PARTY COSTS OR FEES IF RECOVERABLE PURSUANT TO APPLICABLE LAW. THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE UPLOADCARE’S LIABILITY FOR UPLOADCARE’S GROSS NEGLIGENCE, FRAUD OR INTENTIONAL, WILLFUL, MALICIOUS OR RECKLESS MISCONDUCT. THIS SECTION SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF YOUR RELATIONSHIP WITH UPLOADCARE.`}</p>
    <p>{`YOU AGREE THAT IN THE EVENT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF UPLOADCARE’S ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEB SITE, PROPERTY, PRODUCT, SERVICE, OR OTHER UPLOADCARE CONTENT OWNED OR CONTROLLED BY UPLOADCARE, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR EXPLOITATION OF ANY WEBSITE, PROPERTY, PRODUCT, SERVICE, OR OTHER UPLOADCARE CONTENT OWNED OR CONTROLLED BY UPLOADCARE.`}</p>
    <p>{`BY ACCESSING THE SERVICES, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR." THE OBLIGATIONS UNDER THIS SECTION SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOUR USE OF THE SERVICES.`}</p>
    <h2 {...{
      "id": "19-indemnification"
    }}><a parentName="h2" {...{
        "href": "#19-indemnification"
      }}>{`19. Indemnification`}</a></h2>
    <p>{`You agree to indemnify and hold Us, and our respective employees, directors, officers, and agents harmless from any liabilities, claims, expenses or demands, including reasonable attorneys’ fees, arising directly or indirectly out of (i) your misuse of the Services or Website, (ii) your violation of the laws, rules, and regulations, (iii) your breach of this Agreement, (iv) your infringement of any intellectual property or other rights of any third party, including by breach of any license agreement with any third party resulting in the unauthorized uploading or storage of the third party’s content or files or other intellectual property on our server, and (v) any other matter for which you are responsible for hereunder or under law. You agree that your use of the Services shall be in compliance with all applicable laws and regulations. The obligations under this section shall survive any termination or expiration of this Agreement or your use of the Services.`}</p>
    <h2 {...{
      "id": "20-privacy-policy"
    }}><a parentName="h2" {...{
        "href": "#20-privacy-policy"
      }}>{`20. Privacy Policy`}</a></h2>
    <p>{`Uploadcare will process your information or data including personal information in accordance with Uploadcare’s Privacy Policy available at `}<a parentName="p" {...{
        "href": "https://uploadcare.com/about/privacy-policy/"
      }}>{`https://uploadcare.com/about/privacy-policy/`}</a>{`.`}</p>
    <h2 {...{
      "id": "21-ofac-compliance"
    }}><a parentName="h2" {...{
        "href": "#21-ofac-compliance"
      }}>{`21. OFAC Compliance`}</a></h2>
    <p>{`The Services may be subject to export control and economic sanctions laws and regulations administered or enforced by the United States Department of Commerce, Department of Treasury's Office of Foreign Assets Control (`}<em parentName="p">{`“OFAC”`}</em>{`), Department of State, and other United States authorities (collectively, `}<em parentName="p">{`“U.S. Trade Laws”`}</em>{`). You may not use the Services to export or re-export, or permit the export or re-export, of software or technical data in violation of U.S. Trade Laws. In addition, by using the Services, you represent and warrant that you are not (a) an individual, organization or entity organized or located in a country or territory that is the target of OFAC sanctions (including Cuba, Iran, Syria, Sudan, North Korea, or the Crimea region of Ukraine); (b) designated as a Specially Designated National or Blocked Person by OFAC or otherwise owned, controlled, or acting on behalf of such a person; (c) otherwise a prohibited party under U.S. Trade Laws; or (d) engaged in nuclear, missile, chemical or biological weapons activities to which U.S. persons may not contribute without a U.S. Government license. Unless otherwise provided with explicit written permission, Uploadcare also does not register, and prohibits the use of any of our Services in connection with, any Country-CodeExample Top Level Domain Name (`}<em parentName="p">{`“ccTLD”`}</em>{`) for any country or territory that is the target of OFAC sanctions. The obligations under this section shall survive any termination or expiration of this Agreement or your use of the Services.`}</p>
    <h2 {...{
      "id": "22-hipaa-compliance"
    }}><a parentName="h2" {...{
        "href": "#22-hipaa-compliance"
      }}>{`22. HIPAA Compliance`}</a></h2>
    <p>{`The use of the Services implies that you will not store, transmit or otherwise process any content or files that fall within the definition of “Protected Health Information” under the HIPAA Privacy Rule (45 C.F.R. Section 164.051), unless User and Uploadcare Inc. separately enter into a HIPAA Business Associate Agreement (`}<em parentName="p">{`"BAA"`}</em>{`), which may be done by sending an inquiry to `}<a parentName="p" {...{
        "href": "mailto:help@uploadcare.com"
      }}>{`help@uploadcare.com`}</a>{`.`}</p>
    <p>{`Otherwise, Users are solely responsible for compliance with all applicable laws governing the privacy and security of personal data, including medical or other sensitive data if any. We specifically disclaim any representation or warranty that the Services without entering into a BAA, as offered, comply with HIPAA. Storing and permitting access to “Protected Health Information” without entering in a BAA is a material violation of this Agreement, and grounds for immediate account termination.`}</p>
    <p>{`If you have questions about handling “Protected Health Information” via the Services, please contact us at `}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`trust@uploadcare.com`}</a>{`.`}</p>
    <h2 {...{
      "id": "23-us-government-rights"
    }}><a parentName="h2" {...{
        "href": "#23-us-government-rights"
      }}>{`23. U.S. Government Rights`}</a></h2>
    <p>{`The Services are “commercial item”, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of, or contractor to, the US Government, you receive only those rights with respect to such software as are granted to all other end users under license, in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other US Government licensees and their contractors. If you are a government agency that has a need for rights not granted under the Agreement, it must negotiate with Uploadcare to determine if there are acceptable terms for granting those rights, and mutually acceptable written terms specifically granting those rights must be included in any applicable agreement.`}</p>
    <h2 {...{
      "id": "24-force-majeure"
    }}><a parentName="h2" {...{
        "href": "#24-force-majeure"
      }}>{`24. Force Majeure`}</a></h2>
    <p>{`Neither party shall be liable or responsible to the other party, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any obligations to make payments), when and to the extent such failure or delay is caused by any act beyond the party’s reasonable control, including but not limited to, acts of God; flood, fire or explosion; war, terrorism, invasion, riot or other civil unrest; epidemic, pandemic or similar outbreak; embargoes or blockades in effect on or after the date of this Agreement; governmental or regulatory action; or national or regional emergency (each of the foregoing, a `}<em parentName="p">{`“Force Majeure Event”`}</em>{`), in each case, provided that the affected party uses diligent efforts to end the failure or delay and minimize the effects of such Force Majeure Event.`}</p>
    <h2 {...{
      "id": "25-relationship-of-parties"
    }}><a parentName="h2" {...{
        "href": "#25-relationship-of-parties"
      }}>{`25. Relationship of Parties`}</a></h2>
    <p>{`The parties expressly understand and agree that their relationship is that of independent contractors. Nothing in this Agreement shall constitute one party as an employee, agent, joint venture partner or servant of another. This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.`}</p>
    <h2 {...{
      "id": "26-notice"
    }}><a parentName="h2" {...{
        "href": "#26-notice"
      }}>{`26. Notice`}</a></h2>
    <p>{`You agree to transact with us electronically. Your affirmative act of using the Services constitutes your acceptance signature to these Agreement. We may provide notices to you electronically (1) via email if you have provided us with a valid email address or (2) by posting the notice on a website designated by us for this purpose. The delivery of any notice is effective when sent or posted by us, regardless of whether you read the notice or actually receive delivery. You can withdraw your consent to receive notices electronically by discontinuing your use of the Service.`}</p>
    <p>{`You can contact Uploadcare at `}<a parentName="p" {...{
        "href": "mailto:help@uploadcare.com"
      }}>{`help@uploadcare.com`}</a>{`, or at:`}</p>
    <p>{`Uploadcare, Inc
18401 Collins Ave, Ste. 100-241,
Sunny Isles Beach, FL, 33160
United States of America.`}</p>
    <p>{`Notices required or permitted to be given to Uploadcare under this Agreement shall be in writing and shall be deemed to be sufficiently given: (i) one business day after being sent by overnight courier; (ii) three business days after being sent by registered mail, return receipt requested; or (iii) one business day after being sent by email (provided that you do not receive a response that the message could not be delivered or an out-of-office reply). You shall provide immediate notice to Uploadcare in the event you change your contact details. Either Party may change its address(es) for notice by providing notice to the other in accordance with this section.`}</p>
    <h2 {...{
      "id": "27-governing-law-and-dispute-resolution"
    }}><a parentName="h2" {...{
        "href": "#27-governing-law-and-dispute-resolution"
      }}>{`27. Governing Law and Dispute Resolution`}</a></h2>
    <p>{`Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of the Services will be governed by the laws of the state of California, U.S.A., excluding its conflict of law provisions. The parties agree that the UN Convention on Contracts for the International Sale of Goods shall not apply to the Agreement nor to any dispute or transaction arising out of the Agreement. Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by three arbitrators appointed in accordance with such Rules. The arbitration shall take place in Los Angeles, California, in the English language and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees.`}</p>
    <p><strong parentName="p">{`Waiver of Jury Trial and Class or Consolidated Actions`}</strong>{`. YOU WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. YOU ARE INSTEAD CHOOSING TO HAVE CLAIMS AND DISPUTES RESOLVED BY ARBITRATION. IN ANY LITIGATION BETWEEN YOU AND UPLOADCARE OVER WHETHER TO VACATE OR ENFORCE AN ARBITRATION AWARD, YOU WAIVE ALL RIGHTS TO A JURY TRIAL, AND ELECT INSTEAD TO HAVE THE DISPUTE BE RESOLVED BY A JUDGE. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Uploadcare is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth below.`}</p>
    <p>{`In the event that the binding arbitration provision above is found not to apply to you or to a particular claim or dispute, either as a result of your decision to opt-out of the Agreement or a court order, you agree that any claim or dispute that has arisen or may arise between you and Uploadcare must be resolved exclusively by a state or federal court located in Los Angeles, California. You and Uploadcare agree to submit to the personal jurisdiction of the courts located within Los Angeles, California for the purpose of litigating all such claims or disputes.`}</p>
    <h2 {...{
      "id": "28-miscellaneous"
    }}><a parentName="h2" {...{
        "href": "#28-miscellaneous"
      }}>{`28. Miscellaneous`}</a></h2>
    <p>{`This Agreement and any applicable Order Form constitutes the entire agreement between Uploadcare and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of Uploadcare, or by the posting by Uploadcare of a revised version. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect. The failure of Uploadcare to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. Subject to prior written consent of Uploadcare, you may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; Uploadcare may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      